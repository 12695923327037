import React from 'react';
// import { navigate } from '@reach/router';
import { StaticQuery } from 'gatsby';

import PotockLink from '../components/PotockLink';

// import LocaleContext from '../context/Locale';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import FullScreenContainer from '../components/FullScreenContainer';

// Styled Components
// Make shadow like in cover
// const Shadow = styled.div`
//   > h1,
//   > h2 {
//     text-shadow: 8px 0px 4px rgba(0, 0, 0, 0.32);
//     line-height: 2.5rem;
//   }

//   > a.btn-cta {
//     box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.16);

//     &:hover {
//       box-shadow: none;
//     }
//   }
// `;

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "cover.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className + ' full-screen w-full'}
          style={{
            paddingTop: '4rem',
          }}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const SectionWithCoverBackground = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;

// Main Component
function IndexPage() {
  // const { activeLocale } = useContext(LocaleContext);

  // useEffect(() => {
  //   navigate(`/${activeLocale.toLowerCase()}`);
  // });

  return (
    <div>
      <FullScreenContainer>
        <div className="px-6 container mx-auto w-full block pt-20 text-center">
          <h1 className="text-white text-3xl mb-3">
            Wygląda na to, że ta strona nie istnieje.
          </h1>
          <PotockLink className="btn bg-white mt-6 py-2 px-4 rounded" to="/">
            Wróć do strony głównej
          </PotockLink>
        </div>
      </FullScreenContainer>
    </div>
  );
}

export default IndexPage;
